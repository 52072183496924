import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { BookingForm } from "../context/context"
import HeroSection from "../containers/hero-section-dynamic"
import Instagram from "../containers/instagram"
import ContentBlock from "../containers/content-block"
import Carousel from "../containers/carousel"
import ContactUs from "../containers/contact-us"
import laceLogo from "../images/lace.png"
import SaltHero from "../images/salt-hero.png"
import Eat from "../images/eat-eat-eat.png"
import ContentArea from "../dynamic-zone/content-area"
const LaceBeach = ({ data, location }) => {
  const formData = { id: 1, name: "Lace Beach Food" }
  const { bookingForm, setBookingForm } = useContext(BookingForm)

  useEffect(() => {
    setBookingForm({
      open: false,
      type: "Lace Restaurant",
      id: 6,
      eventId: 6,
      name: "Lace Restaurant",
      resTime: [
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
      ],
    })
  }, [])

  return (
    <Layout
      location={location}
      logo={laceLogo}
      color="bg-rednew-50"
      ctaColor="bg-rednew-50"
      textCtaColor="text-white"
      formData={formData}
    >
      <Seo title="Lace Beach Food" />
      <HeroSection
        image={
          data.strapiRestaurant.HeroSectionRestaurants.backgroundImageRestaurant
        }
        smallImage={data.strapiRestaurant.MobileHeroSection.image}
        sectionType={"restaurant"}
      />

      <ContentArea
        carouselTopImage={Eat}
        color="bg-rednew-50"
        contentTypes={data.strapiRestaurant.pageContent}
        title={data.strapiRestaurant.restaurantName}
        // instaNodes={data.allInstaNode.edges}
      />
      <ContactUs
        color="bg-rednew-50"
        contactHeaderColor="text-white"
        contactSubHeaderColor="text-white"
        contactContentColor="text-white"
        contactCtaColor="text-white"
        contactCtaBorderColor="border-white"
      />
      {/* <Instagram title={"lace_beach_food"} ellipse bgColor="#F6BE35" /> */}
    </Layout>
  )
}

export default LaceBeach

export const pageQuery = graphql`
  query MyQueryThird {
    strapiRestaurant(strapi_id: { eq: 1 }) {
      id
      restaurantName
      HeroSectionRestaurants {
        altTag
        backgroundImageRestaurant {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
      MobileHeroSection {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                formats: WEBP
                placeholder: DOMINANT_COLOR
              )
            }
          }
        }
      }
      pageContent {
        ... on STRAPI__COMPONENT_MAIN_COMPONENTS_RESTAURANT_CAROUSEL {
          id
          cloudText
          strapi_component
          RestaurantCarouselImage {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80)
                }
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_MAIN_COMPONENTS_RESTAURANT_CONTENT_BLOCK {
          id
          strapi_component
          text
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`
